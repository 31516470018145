<template>
  <!-- purchase START -->
  <div class="purchase">

    <!-- purchase:products START -->
    <div class="products products-purchase">
      <!-- purchase:products:product START -->
      <product
        v-for="product in cart.items"
        :key="product.id"
        :product="product"
      />
      <!-- purchase:products:product END -->

      <!-- purchase:products:total START -->
      <div class="total">
        <div class="total-text" v-html="$t('cart.total')"></div>
        <div
          class="total-number"
          v-html="`${cart.currencySymbol} ${priceFormat(cart.sumTotal)}`"
        ></div>
      </div>
      <!-- purchase:products:total START -->
    </div>
    <!-- purchase:products END -->

    <!-- purchase:products:payback START -->
    <div class="payback">
      <!-- purchase:products:payback:infos START -->
      <div
        class="info"
        v-html="$t('cart.payback')"
      ></div>
      <el-button
        class="btn-info"
        type="text"
        v-on:click="dialogVisible = true"
      >i</el-button>
      <!-- purchase:products:payback:infos END -->

      <!-- form:payback START -->
      <el-form
        status-icon
        :rules="rules"
        ref="paybackForm"
        :model="paybackForm"
        class="login-form"
        :statusIcon="false"
        @submit.prevent.native
      >
        <!-- form:payback:title START -->
        <el-form-item prop="number">
          <span class="label" v-html="$t('payback.number')"></span>
          <el-input
            :placeholder="'XXXX-XXXX-XXXX-XXXX'"
            v-mask="'XXXX-XXXX-XXXX-XXXX'"
            v-model="paybackForm.number"
          ></el-input>
        </el-form-item>
        <!-- form:payback:title END -->
      </el-form>
      <!-- form:payback END -->

      <!-- form:voucher START -->
      <el-form
        status-icon
        :rules="voucherRules"
        ref="voucherForm"
        :model="voucherForm"
        class="login-form voucher-form"
        :statusIcon="false"
        @submit.prevent.native="addVoucher('voucherForm')"
      >
        <!-- form:voucher:code START -->
        <el-form-item prop="number">
          <span class="label" v-html="$t('voucher.code')"></span>
          <el-input
            :placeholder="''"
            v-model="voucherForm.code"
            @keyup.enter.native="addVoucher('voucherForm')"
          ></el-input>
        </el-form-item>
        <!-- form:voucher:code END -->

        <el-button
          :class="[
            'btn-default',
            { 'btn-disabled' : !voucherForm.code }
          ]"
          type="primary"
          v-on:click="addVoucher('voucherForm')"
          v-html="$t('voucher.redeem')"
        ></el-button>
      </el-form>
      <!-- form:voucher END -->

      <div
        class="vouchers"
      >
        <div
          class="voucher"
          v-for="(voucher, voucherIndex) in vouchers"
          :key="`vouchers-voucher-${voucherIndex}`"
        >
          <span
            class="code"
            v-html="voucher.code"
          ></span>
          <span
            class="delete"
            v-on:click="deleteVoucher(voucher.id)"
          ></span>
        </div>
      </div>

      <!-- purchase:products:payback:modal START -->
      <dialog-default
        :title="dialogTitle"
        :headline="dialogHeadline"
        :description="dialogDescription"
        :dialogVisible="dialogVisible"
        :dialogClose="dialogClose"
      />
      <!-- purchase:products:payback:modal END -->
    </div>
    <!-- payback START -->

    <!-- purchase:products:payback:submit START -->
    <el-button
      class="btn-default"
      type="primary"
      v-on:click="submitForm('paybackForm')"
    >{{ $t('cart.paynow') }}</el-button>
    <!-- purchase:products:payback:submit END -->

    <!-- purchase:products:payback:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- purchase:products:payback:loading END -->

    <!-- purchase:products:voucher:loading START -->
    <loading-default
      :loadingVisible="loadingVoucherVisible"
      :headline="loadingVoucherHeadline"
      :excerpt="loadingVoucherExcerpt"
    />
    <!-- purchase:products:voucher:loading END -->

  </div>
  <!-- purchase END -->
</template>

<script>
import { mapActions } from 'vuex';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'purchase',
  mixins: [titleMixin],
  components: {
    Product: () => import('../../components/cart/Product.vue'),
    DialogDefault: () => import('../../components/shared/dialog/Default.vue'),
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('cart.mypurchase'),

      /**
       * Dialog
       */
      dialogVisible: false,
      dialogTitle: this.$t('login.dialog.payback.title'),
      dialogHeadline: this.$t('login.dialog.payback.headline'),
      dialogDescription: this.$t('login.dialog.payback.description'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.default.headline'),
      loadingExcerpt: this.$t('loading.default.excerpt'),

      /**
       * Loading (VOUCHER)
       */
      loadingVoucherVisible: false,
      loadingVoucherHeadline: this.$t('loading.voucher.headline'),
      loadingVoucherExcerpt: this.$t('loading.voucher.description'),

      /**
       * Form (Models)
       */
      paybackForm: {
        number: '',
      },

      /**
       * Form (Models)
       */
      voucherForm: {
        code: '',
      },

      /**
       * Form (Rules - Validation)
       */
      rules: {
        number: [
          {
            required: false,
            min: 19,
            max: 19,
            message: this.$t('login.errorformfield'),
          },
        ],
      },
      voucherRules: {
        code: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  computed: {
    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart;
      }
      return this.$store.state.storeCart;
    },
    vouchers() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.vouchers;
      }
      return this.$store.state.storeCart;
    },
  },
  methods: {
    ...mapActions([
      'createOrder',
    ]),
    submitForm(formName) {
      this.loadingVisible = true;
      if (formName === 'paybackForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // create order
            this.createOrder();

            // simulate loading -> fake
            setTimeout(() => {
              const formData = new FormData();
              formData.append('ordered_products', JSON.stringify(this.cart));
              formData.append('total', this.total);
              formData.append('payback_card_number', this.paybackForm.number);

              // Display the key/value pairs
              // formData.forEach((value, key) => {
              //   console.log(`${key}: ${value}`);
              // });

              // disable loading
              this.loadingVisible = false;

              // redirect to thank you screen
              this.$router.push('purchase-thank-you');

              return true;
            }, 1500);
          }
          return false;
        });
      }
    },

    addVoucher(formName) {
      const formData = new FormData();
      if (formName === 'voucherForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVoucherVisible = true;
            formData.append('code', this.voucherForm.code);
            this.$http.post(`${process.env.VUE_APP_BASE_URL}cart/voucher/add`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  this.$store.commit('SET_CART', response.data);

                  // close loading screen
                  this.loadingVoucherVisible = false;
                  this.voucherForm.code = '';
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  // close loading screen
                  this.loadingVoucherVisible = false;
                  this.voucherForm.code = '';
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.serverErrorMessages = this.errorStatus.errors;
                  console.log(this.errorStatus);
                }

                // close loading screen
                this.loadingVoucherVisible = false;
                this.voucherForm.code = '';
              });
          }
        });
      }
    },

    deleteVoucher(i) {
      this.loadingVoucherVisible = true;
      const formData = new FormData();
      formData.append('voucherID', i);
      // Update cart
      this.$http.post(`${process.env.VUE_APP_BASE_URL}cart/voucher/delete`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$store.commit('SET_CART', response.data);

            // close loading screen
            this.loadingVoucherVisible = false;
            this.voucherForm.code = '';
          } else {
            this.globalErrorMessage = true;
            this.resetForm.agbs = false;

            // close loading screen
            this.loadingVoucherVisible = false;
            this.voucherForm.code = '';
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            this.serverErrorMessages = this.errorStatus.errors;
            console.log(this.errorStatus);
          }

          // close loading screen
          this.loadingVoucherVisible = false;
          this.voucherForm.code = '';
        });
    },

    resetVoucherForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
      }
    },

    priceFormat(price) {
      let activeLang = 'de-DE';
      if (localStorage.getItem('lang') === 'en_US') {
        activeLang = 'en-US';
      }
      return new Intl.NumberFormat(
        activeLang,
        {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        },
      ).format(price);
    },

    dialogClose() {
      this.dialogVisible = false;
    },
  },
  watch: {
    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart;
      }
      return this.$store.state.storeCart;
    },
  },
};
</script>
